import {useState} from 'react';
import {useRouter, useSearchParams} from 'next/navigation';
import {Input} from '@delorand/ui/src/input';
import {LoadingBlur} from '@delorand/ui/src/loader';
import {toast} from '@delorand/ui/src/toast';
import {
  EyeIcon,
  EyeSlashIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline';
import {zodResolver} from '@hookform/resolvers/zod';
import {client} from 'c/server/api';
import {useStore} from 'c/store/store';
import {signIn} from 'next-auth/react';
import {useForm, useWatch} from 'react-hook-form';
import {mutate} from 'swr';
import useSWRMutation from 'swr/mutation';
import {SignupS, signupS} from '../../../../../packages/schema/dto/shared';

const RegisterForm = ({email, reset}: {email: string; reset: () => void}) => {
  const router = useRouter();
  const searchParams = useSearchParams();

  const afterAuth = useStore(state => state.afterAuth);

  const {
    register,
    handleSubmit,
    formState: {errors, isValid},
    control,
  } = useForm<SignupS>({
    resolver: zodResolver(signupS),
    defaultValues: {email},
  });

  const watch = useWatch({control});

  const [visble, setVisible] = useState(false);

  const {trigger, isMutating} = useSWRMutation(
    ['session.signup'],
    async (
      _,
      {
        arg,
      }: {
        arg: Omit<SignupS, 'confirmPassword'>;
      }
    ) => client.customer.signup.mutate(arg),
    {
      onSuccess: async () => {
        const promise = signIn('credentials', {
          callbackUrl: '',
          redirect: false,
          email: watch.email,
          password: watch.password,
        });
        toast.promise(promise, {
          loading: 'Signing you in...',
          success: res =>
            res?.ok ? `Your'e Signed in` : `Something went wrong`,
          error: `Something went wrong`,
        });

        const res = await promise;
        if (res?.ok) {
          afterAuth && afterAuth();
          router.replace(`${searchParams.get('callbackUrl') || '/'}`);
          location.reload();
        }
      },
    }
  );

  return (
    <form
      onSubmit={handleSubmit(({email, password}) => trigger({email, password}))}
      className="flex flex-col gap-2"
    >
      {isMutating && <LoadingBlur />}
      {/* <div className=' flex items-center'>
          {(fError || error) && (
            <p className='text-center mx-auto text-sm text-orange-600'>
              {fError?.message.toLowerCase().split('_').join(' ')}
              <span className=''>{error?.response?.data}</span>
            </p>
          )}
        </div> */}
      <div className="flex gap-4">
        <Input
          disabled
          touched={true}
          error={errors.email?.message?.toString()}
          type="email"
          label="E-mail"
          {...register('email')}
        />
        <button type="button" className="mt-4" onClick={reset}>
          <PencilSquareIcon width={20} />
        </button>
      </div>

      <Input
        touched={true}
        error={errors.password?.message?.toString()}
        type={visble ? 'text' : 'password'}
        label="Password"
        {...register('password')}
      >
        <button type="button" onClick={() => setVisible(x => !x)}>
          {visble ? <EyeIcon width={23} /> : <EyeSlashIcon width={23} />}
        </button>
      </Input>

      <Input
        touched={true}
        error={errors.confirmPassword?.message?.toString()}
        type={visble ? 'text' : 'password'}
        label="Confirm Password"
        {...register('confirmPassword')}
      >
        <button type="button" onClick={() => setVisible(x => !x)}>
          {visble ? <EyeIcon width={23} /> : <EyeSlashIcon width={23} />}
        </button>
      </Input>
      <button
        type="submit"
        className="mx-auto mt-3 w-full rounded-[12px] bg-white py-2 text-black drop-shadow-md"
      >
        Register
      </button>
    </form>
  );
};

export default RegisterForm;
