import {useRouter, useSearchParams} from 'next/navigation';
import Button from '@delorand/ui/src/button';
import {Input} from '@delorand/ui/src/input';
import {toast} from '@delorand/ui/src/toast';
import {PencilSquareIcon} from '@heroicons/react/24/outline';
import {zodResolver} from '@hookform/resolvers/zod';
import {useStore} from 'c/store/store';
import {signIn} from 'next-auth/react';
import {useForm} from 'react-hook-form';
import useSWRMutation from 'swr/mutation';
import {LoginS, loginS} from '../../../../../packages/schema/dto/shared';

const LoginForm = ({email, reset}: {email: string; reset: () => void}) => {
  const searchParams = useSearchParams();
  const router = useRouter();

  const afterAuth = useStore(state => state.afterAuth);

  const {trigger, isMutating, data} = useSWRMutation(
    ['login'],
    (_, {arg}: {arg: LoginS}) =>
      signIn('credentials', {
        callbackUrl: '',
        redirect: false,
        ...arg,
      }),
    {
      onSuccess: res => {
        if (!res?.ok) {
          toast.error('Something went wrong');
          return;
        }
        router.replace(`${searchParams.get('callbackUrl') ?? '/'}`);
        if (afterAuth) {
          afterAuth();
        }
        location.reload();
      },
    }
  );

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<LoginS>({
    resolver: zodResolver(loginS),
    defaultValues: {email},
  });

  return (
    <form
      onSubmit={handleSubmit(values => trigger(values))}
      className="flex flex-col gap-3"
    >
      <div className="mt-2 flex items-center">
        {data?.error && <p className="error">{data.error.toLowerCase()}</p>}
      </div>

      <div className="flex gap-4">
        <Input
          disabled
          touched={true}
          error={errors.email?.message?.toString()}
          type="email"
          label="E-mail"
          {...register('email')}
        />
        <button type="button" className="mt-4" onClick={reset}>
          <PencilSquareIcon width={20} />
        </button>
      </div>
      <Input
        placeholder="password"
        touched={true}
        error={errors.password?.message?.toString()}
        label="Password"
        type="password"
        {...register('password')}
        autoFocus
      />

      <button
        type="button"
        onClick={() => {
          router.push('/forgot-password');
        }}
        className="mb-2 mt-5 text-center text-sm text-custom-white"
      >
        forgot password?
      </button>

      <Button type="submit" isLoading={isMutating}>
        SIGN IN
      </Button>
    </form>
  );
};

export default LoginForm;
